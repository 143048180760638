import {PipeType} from '../enums/pipe-type.enum';
import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';
import JSX from '../jsx.compiler';
import {CREATED_ON} from './shared/created-on';

export const MEMBERSHIP_MODULE: Module = {
  id: Collections.Membership,
  name: 'Membership',
  layout: {
    sort: CREATED_ON.sort,
    filterModule: {
      persist: true,
      schema: {
        properties: {
          email: {type: 'string'},
          active: {type: 'string', default: null},
          cigarSmoker: {type: 'string', default: null},
          interestedInCigarBox: {type: 'string', default: null},
          invitedBy: {type: 'string'},
        }
      },
      definitions: {
        active: {
          label: 'ACTIVE',
          component: {
            type: 'select',
            configuration: {
              reset: true,
              dataSet: [
                {name: 'Any', value: null},
                {name: 'Yes', value: 'Yes'},
                {name: 'No', value: 'No'},
              ]
            }
          }
        },
        cigarSmoker: {
          label: 'Cigar Smoker',
          component: {
            type: 'select',
            configuration: {
              reset: true,
              dataSet: [
                {name: 'Any', value: null},
                {name: 'Yes', value: 'Yes'},
                {name: 'No', value: 'No'},
              ]
            }
          }
        },
        interestedInCigarBox: {
          label: 'Interested In a Cigar Box',
          component: {
            type: 'select',
            configuration: {
              reset: true,
              dataSet: [
                {name: 'Any', value: null},
                {name: 'Yes', value: 'Yes'},
                {name: 'No', value: 'No'},
              ]
            }
          }
        },
        email: {
          label: 'EMAIL',
          component: {
            type: 'input',
            configuration: {
              type: 'email'
            }
          }
        },
        invitedBy: {
          label: 'Referred By',
          component: {
            type: 'input',
            configuration: {
              type: 'email'
            }
          }
        }
      },
      segments: [
        {
          type: 'empty',
          fields: ['/email', '/active', '/cigarSmoker', '/interestedInCigarBox', '/invitedBy']
        }
      ]
    },
    instance: {
      segments: [
        {
          title: 'Membership',
          columnsDesktop: 6,
          fields: [
            '/id',
            '/active',
            '/notifications',
            '/cigarSmoker',
            '/interestedInCigarBox'
          ]
        },
				{
					title: 'Sign Up',
          columnsDesktop: 6,
					fields: [
						'/firstName',
						'/lastName',
						'/email',
						'/phone',
						'/dob',
						'/tShirtSize',
						'/invitedBy'
					]
				},
        {
          title: 'Invited Members',
          array: '/invitedMembers',
          fields: ['/firstName', '/lastName', '/email', '/phone']
        },
        {
					components: [{
						selector: 'note-view'
					}]
				}
      ]
    },
    table: {
      tableColumns: [
        CREATED_ON.column(),
        {key: '/firstName', label: 'First Name'},
        {key: '/lastName', label: 'Last Name'},
        {key: '/email', label: 'Email'},
        {key: '/phone', label: 'Phone'},
        {key: '/dob', label: 'DOB', disabled: true},
        {key: '/tShirtSize', label: 'T-Shirt Size', disabled: true},
        {key: '/interestedInCigarBox', label: 'Interested In Cigar Box', disabled: true},
        {
          key: '/notifications',
          label: 'User Notifications',
          pipe: [PipeType.Custom],
          pipeArguments: {
            0: v => `${v} days`
          },
          disabled: true
        },
        {key: '/active', label: 'Active'},
        {key: '/cigarSmoker', label: 'Cigar Smoker'},
        {key: '/invitedBy', label: 'Invited By'},
      ],
      actions: [
				{
					value: it => JSX(<jms-e-notes data-id={it.id} />)
				}
			]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      firstName: {type: 'string'},
      lastName: {type: 'string'},
      email: {type: 'string'},
      phone: {type: 'string'},
      dob: {type: 'string'},
      tShirtSize: {type: 'string'},
      paymentMethod: {type: 'string'},
      invitedBy: {type: 'string'},
      claimedOn: {type: 'number'},
      active: {type: 'string'},
      notifications: {type: 'array'},
      cigarSmoker: {type: 'string'},
      cigarBoxMember: {type: 'string'},
      interestedInCigarBox: {type: 'string'},
      invitedMembers: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            firstName: {type: 'string'},
            lastName: {type: 'string'},
            email: {type: 'string'},
            phone: {type: 'string'}
          }
        }
      },
      lastNotificationOn: {type: 'number'},
      ...CREATED_ON.property
    }
  },
  definitions: {
		id: {label: 'Code'},
    active: {
      label: 'User Active',
      component: {
        type: 'select',
        configuration: {
          dataSet: [
            {name: 'Yes', value: 'Yes'},
            {name: 'No', value: 'No'}
          ]
        }
      }
    },
    notifications: {
      label: 'User Notifications',
      component: {
        type: 'select',
        configuration: {
          multiple: true,
          dataSet: [
            {name: '2 days', value: 2},
            {name: '7 days', value: 7},
            {name: '10 days', value: 10},
          ]
        }
      }
    },
    cigarSmoker: {
      label: 'Cigar Smoker',
      component: {
        type: 'select',
        configuration: {
          dataSet: [
            {name: 'Yes', value: 'Yes'},
            {name: 'No', value: 'No'}
          ]
        }
      }
    },
    interestedInCigarBox: {
      label: 'Interested in Cigar Box',
      component: {
        type: 'select',
        configuration: {
          dataSet: [
            {name: 'Yes', value: 'Yes'},
            {name: 'No', value: 'No'}
          ]
        }
      }
    },
    cigarBoxMember: {
      label: 'Cigar Box Member',
      component: {
        type: 'select',
        configuration: {
          dataSet: [
            {name: 'Yes', value: 'Yes'},
            {name: 'No', value: 'No'}
          ]
        }
      }
    },
    paymentMethod: {
      component: {
        type: 'select',
        configuration: {
          dataSet: [
            {name: 'Venmo', value: 'Venmo'},
            {name: 'Credit Card', value: 'Credit Card'},
            {name: 'Check', value: 'Check'},
            {name: 'EFT/ Wire Transfer', value: 'EFT/ Wire Transfer'},
          ]
        }
      }
    },
    tShirtSize: {
      component: {
        type: 'select',
        configuration: {
          dataSet: [
            {name: 'S', value: 'S'},
            {name: 'M', value: 'M'},
            {name: 'L', value: 'L'},
            {name: 'XL', value: 'XL'},
            {name: 'XXL', value: 'XXL'},
          ]
        }
      }
    },
    'invitedMembers/firstName': {
      columnsDesktop: 6
    },
    'invitedMembers/lastName': {
      columnsDesktop: 6
    },
    'invitedMembers/email': {
      columnsDesktop: 6
    },
    'invitedMembers/phone': {
      columnsDesktop: 6
    },
    ...CREATED_ON.definition()
  },
  metadata: {
		subCollections: [
      {name: Collections.Notes}
    ]
	}
};
