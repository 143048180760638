import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';
import JSX from '../jsx.compiler';
import {CREATED_ON} from './shared/created-on';

export const NOTIFICATIONS_MODULE: Module = {
  id: Collections.Notifications,
  name: 'Notifications',
	layout: {
		sort: CREATED_ON.sort,
		table: {
			hideImport: true,
			hideEdit: true,
			hideDelete: true,
			hideAdd: true,
			hideCheckbox: true,
			tableColumns: [
				CREATED_ON.column(false, 'Created On'),
				{key: '/message', label: 'Message'},
				{key: '/type', label: 'Type'}
			],
			actions: [
				{
					value: it => JSX(<jms-e-notes data-id={it.id} />)
				}
			]
		},
		overview: {
			toolbar: []
		}
	},
	schema: {
		id: {type: 'string'},
		type: {type: 'string'},
		message: {type: 'string'},
		member: {type: 'string'},
		date: {type: 'string'},
		...CREATED_ON.property
	},
	metadata: {
		subCollections: [
      {name: Collections.Notes}
    ]
	}
};