import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';
import JSX from '../jsx.compiler';
import {CREATED_ON} from './shared/created-on';
import {EMAIL_PIPE} from './shared/email-pipe';
import {PHONE_PIPE} from './shared/phone-pipe';

export const PROSPECTS_MODULE: Module = {
	id: Collections.Prospects,
	name: 'Prospects',
	layout: {
		sort: {active: '/lastName', direction: 'desc'},
		table: {
			tableColumns: [
				CREATED_ON.column(true, 'Created On'),
				{key: '/firstName', label: 'First Name'},
				{key: '/lastName', label: 'Last Name'},
				{key: '/phone', label: 'Phone', ...PHONE_PIPE},
				{key: '/email', label: 'Email', ...EMAIL_PIPE},
				{key: '/referredBy', label: 'Referred By'}
			],
			actions: [
				{
					value: it => JSX(<jms-e-notes data-id={it.id} />)
				}
			]
		},
		instance: {
			segments: [
				{
					fields: [
						'/firstName',
						'/lastName',
						'/phone',
						'/email',
						'/referredBy',
					]
				},
				{
					components: [{
						selector: 'note-view'
					}]
				}
			]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			firstName: {type: 'string'},
			lastName: {type: 'string'},
			phone: {type: 'string'},
			email: {type: 'string'},
			referredBy: {type: 'string'},
			...CREATED_ON.property
		}
	},
	definitions: {
		email: {
			component: {
				type: 'input',
				configuration: {
					type: 'email'
				}
			}
		},
		...CREATED_ON.definition()
	},
	metadata: {
		subCollections: [
      {name: Collections.Notes}
    ]
	}
};