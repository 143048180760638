/**
 * Shared public environment config
 */
export const ENV_CONFIG = {
  firebase: {
    "projectId": "carbon-members-club",
    "appId": "1:622298759725:web:0c812108e0162cf66f6ea4",
    "storageBucket": "carbon-members-club.appspot.com",
    "apiKey": "AIzaSyB-28z90yk6Id2yOYWSAbzOE-M29M0hfIs",
    "authDomain": "carbon-members-club.firebaseapp.com",
    "messagingSenderId": "622298759725",
    "measurementId": "G-8QETHG1KQH"
  }
};
