import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';
import {CREATED_ON} from './shared/created-on';
import {EMAIL_PIPE} from './shared/email-pipe';

export const CONTACTS_MODULE: Module = {
  id: Collections.Contacts,
  name: 'Contacts',
  layout: {
    editTitleKey: 'name',
    sort: CREATED_ON.sort,
    instance: {
      segments: [
        {
          title: 'GENERAL',
          fields: [
            '/firstName',
            '/lastName',
            '/email',
            '/message'
          ]
        }
      ]
    },
    table: {
      tableColumns: [
        CREATED_ON.column(),
        {key: '/firstName', label: 'First Name'},
        {key: '/lastName', label: 'Last Name'},
        {key: '/email', label: 'Email', ...EMAIL_PIPE},
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      firstName: {type: 'string'},
      lastName: {type: 'string'},
      email: {type: 'string'},
      message: {type: 'string'},
      ...CREATED_ON.property
    }
  },
  definitions: {
    firstName: {label: 'First Name'},
    lastName: {label: 'Last Name'},
    email: {label: 'Email'},
    message: {
      label: 'Message',
      component: {
        type: 'textarea'
      }
    },
    ...CREATED_ON.definition()
  }
};
